
import React, { useState, useEffect } from 'react';
import {
  Layout,
  Container,
  TitleContainer,
  CuoponListContainer,
  CouponItem,
} from "../../style/Home/AirportCouponSection.module.js";
import {
  CategoryContainer,
  CategoryChip,
} from "../../style/Home/RecommendSection.module.js";

import { useNavigate, useLocation } from "react-router-dom";
import { client } from "../../client.js";
import { GET_AIA_COUPON } from "./gql/queries.js";

export const DutyFreeTabLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [coupons, setCoupons] = useState([]);
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const categories = ["ALL", "경복궁-담배", "신라면세점", "대한항공 SKY SHOP"];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get("type");
    if (type === "tabacco") {
      setSelectedCategory("경복궁-담배");
    }
  }, [location.search]);

  useEffect(() => {
    const fetchCoupons = async () => {
      client
        .query({
          query: GET_AIA_COUPON,
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          setCoupons(result.data.getAIAEventCoupon);
        })
        .catch((err) => {
          console.log("error 발생", err);
        });
    };
    fetchCoupons();
  }, []);

  useEffect(() => {
    filterCoupons(coupons, selectedCategory);
  }, [selectedCategory, coupons]);

  const filterCoupons = (coupons, category) => {
    const categoryFiltered = coupons.filter((coupon) => {
      if (coupon.category === "dutyfree") return true;
      else return false;
    });

    if (category === "ALL") {
      setFilteredCoupons(categoryFiltered);
    } else {
      const typeFilterd = categoryFiltered.filter((coupon) => {
        if (category === "신라면세점") {
          return coupon.type === "shilla";
        } else if (category === "대한항공 SKY SHOP") {
          return coupon.type === "skyshop";
        } else if (category === "경복궁-담배") {
          return coupon.type === "kbg";
        }
        return false;
      });
      setFilteredCoupons(typeFilterd);
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleClick = (coupon) => {
    navigate("/AiaCouponDisplay", { state: { coupon } });
  };

  return (
    <Layout>
      <Container>
        <CategoryContainer>
          {categories.map((category) => (
            <CategoryChip
              key={category}
              active={selectedCategory === category}
              onClick={() => handleCategorySelect(category)}
            >
              {category}
            </CategoryChip>
          ))}
        </CategoryContainer>

        <CuoponListContainer>
          {filteredCoupons.map((coupon) => (
            <CouponItem key={coupon.id} onClick={() => handleClick(coupon)}>
              <img src={coupon.imageUrl}></img>
            </CouponItem>
          ))}
        </CuoponListContainer>
      </Container>
    </Layout>
  );
};

