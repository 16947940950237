import { useLocation, useNavigate } from "react-router";
import BackImg from "./img/angle-left 1.png";
import MenuImg from "./img/bars-sort.png";
import WhiskyLogo from "./img/logo.svg";

import MyPageImg from "./img/gnb-my.svg";

export const WhiskyLayout = ({
  children,
  pageName,
  menu,
  iframe,
  setIframeUrl,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMypageBtn = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken)
      return navigate("/loginbsdf", { state: { from: location } });

    navigate("/mypage");
  };

  const handleHomeBtn = () => {
    if (iframe !== null) {
      setIframeUrl(null);
    } else {
      navigate(-1);
    }
  };

  return (
    <main className="font-CustomSans flex flex-col justify-center items-center bg-gray-50 h-full w-full sm:min-w-[360px]">
      <div className="relative bg-white w-full max-w-400 min-h-[60px] shadow-custom sm:min-w-[360px] sm:w-screen">
        <header className="flex flex-col justify-between w-full pl-3 pr-3 border-b-2">
          <nav
            aria-label="header"
            className="flex items-center justify-between h-[60px] mt-2 w-full pr-0"
          >
            <div className="flex items-center justify-between w-full mr-3">
              <div className="w-1/3">
                <button className=" flex justify-start" onClick={handleHomeBtn}>
                  <img
                    className="w-[24px] h-[24px]"
                    src={BackImg}
                    alt="뒤로가기"
                  />
                </button>
              </div>
              <div className="w-2/3 font-pretendard text-[18px] font-semibold text-black text-center flex justify-center">
                {pageName == null ? (
                  <img
                    className="w-[149px] h-[31px]"
                    src={WhiskyLogo}
                    alt="whisky365"
                  />
                ) : (
                  <span className="text-[18px] leading-[21px] flex items-center">
                    {pageName}
                  </span>
                )}
              </div>
              <div className="w-1/3 flex justify-end items-center h-[60]">
                {menu === true ? (
                  <button onClick={handleMypageBtn}>
                    <img
                      src={MyPageImg}
                      alt="메뉴"
                      className="w-[24px] h-[24px]"
                    />
                  </button>
                ) : null}
              </div>
            </div>
          </nav>
        </header>

        {children}
      </div>
    </main>
  );
};
