import React, { useState } from "react";
import Layout from "../Layout";

export const MainBannerAdminPage = () => {
  // 업로드된 이미지 URL들을 관리
  const [imageUrls, setImageUrls] = useState([
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmuQLI3qYm004ARrujfsk5DSvm_ANyVV4jpQ&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmuQLI3qYm004ARrujfsk5DSvm_ANyVV4jpQ&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmuQLI3qYm004ARrujfsk5DSvm_ANyVV4jpQ&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmuQLI3qYm004ARrujfsk5DSvm_ANyVV4jpQ&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmuQLI3qYm004ARrujfsk5DSvm_ANyVV4jpQ&s",
  ]);

  // 이미지 업로드 핸들러
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files); // 선택된 파일들을 배열로 변환

    const newImageUrls = files.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result); // 파일을 읽고 URL을 반환
        reader.readAsDataURL(file);
      });
    });

    // 모든 이미지가 로드되면 상태 업데이트
    Promise.all(newImageUrls).then((urls) => {
      setImageUrls((prevUrls) => [...prevUrls, ...urls]); // 기존 이미지들 뒤에 새로운 이미지 추가
    });
  };

  // 이미지 삭제 핸들러
  const handleImageDelete = (index) => {
    setImageUrls((prevUrls) => prevUrls.filter((_, i) => i !== index)); // 해당 인덱스의 이미지 삭제
  };

  // 이미지 순서 변경 핸들러
  const handleOrderChange = (index, newOrder) => {
    const updatedImageUrls = [...imageUrls];
    const [movedItem] = updatedImageUrls.splice(index, 1); // 기존 항목 제거
    updatedImageUrls.splice(newOrder, 0, movedItem); // 새로운 위치에 항목 삽입
    setImageUrls(updatedImageUrls); // 순서 변경된 배열로 상태 업데이트
  };

  // 파일 입력 버튼 클릭 시 input 클릭 트리거
  const fileInputRef = React.useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click(); // 버튼 클릭 시 파일 입력 창 열기
  };

  return (
    <Layout>
      <div style={{ padding: "20px", position: "relative" }}>
        <h1>메인배너 관리</h1>

        {/* 최상단 우측에 업로드 버튼 */}
        <button
          onClick={triggerFileInput}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            zIndex: 10, // 버튼을 다른 요소 위에 표시
          }}
        >
          이미지 업로드
        </button>

        {/* 테이블 헤더 */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "60px",
            marginBottom: "20px",
            width: "100%", // 추가된 설정
          }}
        >
          <div style={{ flex: "1", fontWeight: "bold" }}>ID</div>
          <div style={{ flex: "1", fontWeight: "bold" }}>Image</div>
          <div style={{ flex: "1", fontWeight: "bold" }}>Order</div>
          <div style={{ flex: "1", fontWeight: "bold" }}>Delete</div>
        </div>

        {/* 이미지 미리보기 */}
        <div>
          {imageUrls.map((imageUrl, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                width: "100%",
              }}
            >
              {/* 인덱스 번호 */}
              <div
                style={{ flex: "1", textAlign: "center", fontWeight: "bold" }}
              >
                {index + 1}
              </div>

              {/* 이미지 미리보기 */}
              <img
                src={imageUrl}
                alt={`Banner Preview ${index + 1}`}
                style={{
                  flex: "1",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  marginRight: "20px", // 이미지와 삭제 버튼 간격
                }}
              />

              {/* 순서 변경 드롭다운 */}
              <select
                value={index}
                onChange={(e) =>
                  handleOrderChange(index, parseInt(e.target.value))
                }
                style={{
                  flex: "1",
                  padding: "5px",
                  backgroundColor: "#f8f9fa",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                {imageUrls.map((_, i) => (
                  <option key={i} value={i}>
                    {i + 1}
                  </option>
                ))}
              </select>

              {/* 삭제 버튼 */}
              <button
                onClick={() => handleImageDelete(index)} // 클릭 시 해당 이미지 삭제
                style={{
                  flex: "1",
                  padding: "5px 10px",
                  backgroundColor: "#dc3545",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Delete
              </button>
            </div>
          ))}
        </div>

        {/* 숨겨진 파일 입력 */}
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
      </div>
    </Layout>
  );
};
