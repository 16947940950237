// /components/Layout/Sidebar.js
import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div
      style={{
        width: "200px",
        background: "#f4f4f4",
        height: "100vh",
        padding: "10px",
      }}
    >
      <nav>
        <ul>
          <li>
            <Link to="/admin/main-banner">메인배너 관리</Link>
          </li>
          <li>
            <Link to="/admin/new">기타 메뉴</Link>
          </li>
          {/* 다른 메뉴도 여기에 추가 */}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
