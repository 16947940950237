import HomeImg from "./img/home.png";
import MyPageImg from "./img/gnb-my.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { client } from "../../client";
import { GET_EXCHANGERATE, GET_WHISKY_PRODUCTS } from "./Query/Whisky.gql";
import { ListModal } from "./Modal/ListModal";

export const MerchandiseList = () => {
  const [data, setData] = useState(null);
  const [id, setId] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [companyId, setCompanyId] = useState(null); // 기본 companyId를 1로 설정

  // const [isAlertModalOpen, setIsAlertModalOpen] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);
  const shareBtn = () => {
    // alert('카카오 공유')
    if (window.Kakao) {
      if (!window.Kakao.isInitialized()) {
        const kakao = window.Kakao;
        kakao.init("bd6295d7c2426350afac958e9355f2c6");
      }

      const kakao = window.Kakao;

      const shareLink = "https://dutyfree-price.com/whisky365";
      // 카카오 링크 공유 설정
      kakao.Link.sendDefault({
        objectType: "feed", // 카카오 링크 공유 여러 type들 중 feed라는 타입
        content: {
          title: "국내 면세점 최저가", // 인자값으로 받은 title
          description: '"깜짝 놀랄 가격!! 세상에 이 가격이 맞아!! "', // 인자값으로 받은 title
          imageUrl: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/test.jpeg",
          link: {
            mobileWebUrl: shareLink, // 위에서 설정된 공유 링크 사용
            webUrl: shareLink,
          },
        },
        buttons: [
          {
            title: "최저가 위스키 둘러보기",
            link: {
              mobileWebUrl: shareLink, // 위에서 설정된 공유 링크 사용
              webUrl: shareLink,
            },
          },
        ],
      });
    } else {
      console.log("kakao 없음");
    }
  };
  const renderFloatingShareBtn = () => {
    // 로컬 스토리지에서 companyId 확인
    const companyId = sessionStorage.getItem("companyId");
    if (companyId == 1 || companyId == null) {
      return (
        <a
          className="absolute bottom-28 right-4"
          style={{
            // right: "calc(50% - 190px)",
            background: "rgba(255, 255, 255, 0.7)", // 반투명 배경
            borderRadius: "50%", // 둥근 배경
            padding: "0px", // 버튼 주변 여백
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // 그림자 효과
            backdropFilter: "blur(10px)", // 블러 효과
          }}
          onClick={shareBtn}
        >
          <img
            src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/dfp_share.png"
            alt="Floating Button"
            className="w-11 h-11 rounded-full shadow-lg cursor-pointer"
          />
        </a>
      );
    }

    // 버튼 숨기기 (렌더링 안 함)
    return null;
  };

  const handleSwiperChange = (e) => {
    const index = e.activeIndex;
    const activeItem = data[index];

    setId(activeItem.id);
  };

  const handleMypageBtn = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken)
      return navigate("/loginbsdf", { state: { from: location } });

    navigate("/mypage");
  };

  const fetchProducts = async (companyId) => {
    try {
      // 전달된 companyId가 null이면 sessionStorage에서 가져옴
      const inputCompanyId =
        companyId == null
          ? parseInt(sessionStorage.getItem("companyId") || "1", 10)
          : companyId;

      console.log("input...", inputCompanyId);

      const res = await client.query({
        query: GET_WHISKY_PRODUCTS,
        variables: { companyId: inputCompanyId }, // companyId를 쿼리 변수로 전달
        fetchPolicy: "no-cache",
      });

      setData(res.data.getWhiskyProducts);
      if (res.data.getWhiskyProducts.length > 0) {
        setId(res.data.getWhiskyProducts[0].id);
      }
    } catch (err) {
      console.error("fail to load products", err);
    }
  };

  // const ModalHandler = () => {
  //   setIsAlertModalOpen(!isAlertModalOpen);
  // };

  useEffect(() => {
    const getExchangeRateFromCookie = () => {
      const cookies = document.cookie
        .split("; ")
        .find((row) => row.startsWith("exchangeRate="));
      return cookies ? cookies.split("=")[1] : null;
    };

    const exchangeRateFromCookie = getExchangeRateFromCookie();
    if (exchangeRateFromCookie) {
      setExchangeRate(exchangeRateFromCookie);
    }
  }, []);

  useEffect(() => {
    // URL에서 companyId 가져오기
    const queryParams = new URLSearchParams(location.search);
    const companyIdFromUrl = queryParams.get("companyId");
    const parsedId = parseInt(companyIdFromUrl, 10);

    // 세션 스토리지에서 companyId 가져오기
    const companyIdFromSession = sessionStorage.getItem("companyId");
    const validCompanyIdFromSession = companyIdFromSession
      ? parseInt(companyIdFromSession, 10)
      : null;

    console.log("parsedId: ", parsedId);
    console.log("companyIdFromSession: ", validCompanyIdFromSession);

    // 유효한 값을 비교하고 설정
    if (!isNaN(parsedId)) {
      console.log("url 값...", parsedId);
      // URL에서 유효한 companyId가 있으면 세션 스토리지 업데이트
      setCompanyId(parsedId);
      sessionStorage.setItem("companyId", parsedId);
    } else if (
      !isNaN(validCompanyIdFromSession) &&
      validCompanyIdFromSession != null
    ) {
      console.log("세션 값...", validCompanyIdFromSession);
      // URL에 유효한 값이 없으면 세션 스토리지의 값을 사용
      setCompanyId(validCompanyIdFromSession);
    } else {
      // 둘 다 유효하지 않으면 기본값 1 사용
      console.log("1로 설정...");
      setCompanyId(1);
      sessionStorage.setItem("companyId", 1);
    }

    // fetchProducts 호출
    // navigate("/whisky365?companyId=" + companyId);
    // fetchProducts(companyId);
  }, []);

  // companyId가 업데이트될 때 fetchProducts 호출
  useEffect(() => {
    if (companyId !== null) {
      fetchProducts(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (data) {
      // 데이터가 로드된 후에 스크롤을 아래로 이동
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }
  }, [data]); // data가 변경될 때만 실행

  const handlePurchase = (e) => {
    window.location.href = `/whisky365/${e}`;
  };

  return (
    <main className="font-CustomSans flex flex-col justify-center items-center bg-gray-50  w-full sm:min-w-[360px] sm:w-screen">
      <div className="bg-backgroundGray w-full max-w-400 shadow-custom sm:min-w-[360px] sm:w-screen">
        <header className="flex flex-col justify-between w-full pb-2 pt-1 pl-3 pr-3 bg-white">
          <nav
            aria-label="header"
            className="flex items-center justify-between h-[60px] mt-2 w-full pr-0"
          >
            <div className="flex items-center justify-between w-full mr-3">
              <div className="w-1/5 pl-1">
                {/* companyId가 2일 때 HomeImg 숨김 */}
                {companyId !== 2 && (
                  <button
                    className="flex justify-start"
                    onClick={() => (window.location.href = "/")}
                  >
                    <img src={HomeImg} alt="홈" />
                  </button>
                )}
              </div>
              <div
                className="w-full
               font-pretendard font-semibold text-black text-center flex justify-center whitespace-nowrap"
                onClick={shareBtn}
              >
                <span className="text-[18px] leading-[20px] flex items-center text-premium">
                  프리미엄 위스키 특별 할인 혜택
                </span>
              </div>
              <div className="w-1/5 flex justify-end items-center h-[60]">
                <button onClick={handleMypageBtn}>
                  <img src={MyPageImg} alt="메뉴" />
                </button>
              </div>
            </div>
          </nav>
        </header>

        <div className=" relative ">
          {data !== null ? (
            <>
              <div className=" pb-[50px] pt-[25px] mx-3">
                <Swiper
                  spaceBetween={1}
                  slidesPerView={1}
                  centeredSlides={true}
                  onActiveIndexChange={(e) => handleSwiperChange(e)}
                  pagination={{ el: ".custom-pagination", clickable: true }}
                  modules={[Pagination]}
                  style={{ zIndex: 0 }}
                >
                  {data.map((item, idx) => (
                    <SwiperSlide key={idx}>
                      <div className=" mx-6 bg-white shadow-product">
                        <figure className="flex justify-center h-[400px]">
                          <img
                            src={item.img_url}
                            alt={`${item.name}`}
                            className="object-contain w-full max-w-[400px] h-auto"
                          />
                        </figure>
                        <div className="flex flex-col items-center text-center bg-white pb-8">
                          <div className="text-[16px] font-semibold mt-2">
                            <p>{item.name}</p>
                          </div>
                          <div className="text-Gray50 mb-1">
                            <p>{item.name_eng}</p>
                          </div>
                          {/* 정상 가격 */}
                          <div className="text-left mt-3">
                            {" "}
                            {/* 위아래 마진 추가 */}
                            <span className="text-[14px] font-normal text-Gray50 leading-[16px]">
                              <del>
                                ${item?.price_normal} (
                                {parseInt(
                                  item?.price_normal * exchangeRate
                                ).toLocaleString()}{" "}
                                원)
                              </del>
                            </span>
                          </div>

                          {/* 상품 가격 */}
                          <div className="text-left mb-5 flex justify-between">
                            <div className="flex w-[100%] items-center space-x-2">
                              {" "}
                              {/* 글자 줄바꿈 방지 및 너비 조정2*/}
                              <span className="text-[18px] font-bold text-red-400">
                                {parseInt(
                                  ((item?.price_normal - item?.price_member) /
                                    item?.price_normal) *
                                    100
                                )}
                                %
                              </span>
                              <span className="text-[18px] font-bold leading-[21px]">
                                ${item?.price_member}
                              </span>
                              <span className="text-[14px] font-normal text-Gray70 leading-[16px] whitespace-nowrap">
                                (
                                {parseInt(
                                  item?.price_member * exchangeRate
                                ).toLocaleString()}{" "}
                                원)
                              </span>
                            </div>
                          </div>
                          {/* 마지막 할인가 섹션
                          <div className="my-3">
                            <p>
                              <span className="font-bold text-[18px]">
                                ${item.price_member}
                              </span>
                              <span className="ml-2 text-Gray50">
                                (
                                {(
                                  item?.price_member * exchangeRate
                                ).toLocaleString()}{" "}
                                원)
                              </span>
                            </p>
                          </div> */}
                          <div className="text-[15px] font-semibold bg-backgroundGray w-fit py-2 px-3">
                            <span>총 {item.reg_stock}병 수량 중</span> &nbsp;
                            <span className="text-Aqua">
                              {item.tot_stock ? item.tot_stock : 0}병 남음
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="custom-pagination mt-5 flex justify-center"></div>
              </div>

              <div className="mb-6 mx-6 items-center ">
                {/* Floating Button */}

                <button
                  onClick={() => handlePurchase(id)}
                  className="bg-black text-white font-semibold py-3 px-12 w-full"
                >
                  상세보기
                </button>
              </div>

              {/* Floating Button */}
              {renderFloatingShareBtn()}
              <a
                href="http://pf.kakao.com/_RjKPG"
                className="absolute bottom-14 right-4 zIndex-100" // 플로팅 위치
              >
                <img
                  src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/qna.png"
                  alt="Floating Button"
                  className="w-12 h-12 rounded-full shadow-lg cursor-pointer"
                />
              </a>

              {/* {isAlertModalOpen && <ListModal onClose={ModalHandler} />} */}
            </>
          ) : null}
        </div>
      </div>
    </main>
  );
};
