import { useEffect, useState } from "react";

import LimitedLogo from "./img/UnionLogo.svg";
import Hook from "./img/Group 20.png";
import Star from "./img/star.png";
import DollarTag from "./img/icons8-price-tag-48.png";
import LowPrice from "./img/icons8-low-price-48.png";

import { useLocation, useNavigate, useParams } from "react-router";
import { ExplanationModal } from "./Modal/ExplanationModal";
import { CompareModal } from "./Modal/CompareModal";
import { FeedbackRating } from "./FeedbackRating";
import { Review } from "./Review";
import { PurchaseModal } from "./Modal/PurchaseModal";
import { WhiskyLayout } from "./WhiskyLayout";
import { jwtDecode } from "jwt-decode";
import { client } from "../../client";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import {
  GET_EXCHANGERATE,
  GET_REVIEW_RATING,
  GET_WHISKY_PRODUCTS_DETAIL,
} from "./Query/Whisky.gql";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

const Whisky = (props) => {
  // 로딩 관리
  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  // 터치
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  // 페이지
  const [activeTab, setActiveTab] = useState("상품상세");
  const [iframeUrl, setIframeUrl] = useState(null);
  const [isExplainModalOpen, setIsExplainModalOpen] = useState(false);
  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [averagePrice, setAveragePrice] = useState(0); // 평균 가격 state
  const [productDescription, setProductDescription] = useState(null);
  const [ratingData, setRatingData] = useState(null);
  const [goodsSeq, setGoodsSeq] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [purchaseUrl, setPurchaseUrl] = useState("/");

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isMobile) {
      if (goodsSeq) {
        const url = `https://m.busandf.com/goods/view?no=${goodsSeq}`;
        if (apiKey) {
          setPurchaseUrl(`${url}&api_key=${apiKey}`);
        }
      }
    } else {
      if (goodsSeq) {
        const url = `https://www.busandf.com/goods/view?no=${goodsSeq}`;
        if (apiKey) {
          setPurchaseUrl(`${url}&api_key=${apiKey}`);
        }
      }
    }
  }, [goodsSeq, apiKey, isMobile]);

  const id = useParams();
  const activeIndex = parseInt(id.productId);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setApiKey(decodedToken.api_key);
      } catch (error) {
        console.error("Failed to decode token", error);
      }
    }
  }, []);

  useEffect(() => {
    const getExchangeRateFromCookie = () => {
      const cookies = document.cookie
        .split("; ")
        .find((row) => row.startsWith("exchangeRate="));
      return cookies ? cookies.split("=")[1] : null;
    };

    const exchangeRateFromCookie = getExchangeRateFromCookie();
    if (exchangeRateFromCookie) {
      setExchangeRate(exchangeRateFromCookie);
    }
  }, []);

  useEffect(() => {
    const fetchDataAndCalculateAverage = async () => {
      try {
        setLoading(true);
        const res = await client.query({
          query: GET_WHISKY_PRODUCTS_DETAIL,
          variables: {
            id: activeIndex,
          },
          fetchPolicy: "no-cache",
        });

        const productDetail = res.data.getWhiskyProductDetail;
        setProductDescription(productDetail.product_description_img_url);
        setData(productDetail);
        setGoodsSeq(productDetail.goods_seq);

        calculateAveragePrice(productDetail?.bs_comparisons); // 평균 가격 계산 함수 호출
      } catch (err) {
        console.error("fail to fetch Data", err);
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndCalculateAverage();
  }, [activeIndex]);

  useEffect(() => {
    let isMounted = true;

    if (data) {
      const fetchRatingData = async () => {
        try {
          const res = await client.query({
            query: GET_REVIEW_RATING,
            variables: { productId: data.id },
            fetchPolicy: "no-cache",
          });
          if (isMounted) {
            setRatingData(res.data.getReviewPage);
          }
        } catch (err) {
          console.error("failed to fetch", err);
        }
      };
      fetchRatingData();
    }

    return () => {
      isMounted = false;
    };
  }, [data]);

  // share btn
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);
  const shareBtn = () => {
    // alert('카카오 공유')
    if (window.Kakao) {
      if (!window.Kakao.isInitialized()) {
        const kakao = window.Kakao;
        kakao.init("bd6295d7c2426350afac958e9355f2c6");
      }

      const kakao = window.Kakao;

      const shareLink = "https://dutyfree-price.com/whisky365";
      // 카카오 링크 공유 설정
      kakao.Link.sendDefault({
        objectType: "feed", // 카카오 링크 공유 여러 type들 중 feed라는 타입
        content: {
          title: "국내 면세점 최저가", // 인자값으로 받은 title
          description: '"깜짝 놀랄 가격!! 세상에 이 가격이 맞아!! "', // 인자값으로 받은 title
          imageUrl: "https://dfx-img.s3.ap-northeast-2.amazonaws.com/test.jpeg",
          link: {
            mobileWebUrl: shareLink, // 위에서 설정된 공유 링크 사용
            webUrl: shareLink,
          },
        },
        buttons: [
          {
            title: "최저가 위스키 둘러보기",
            link: {
              mobileWebUrl: shareLink, // 위에서 설정된 공유 링크 사용
              webUrl: shareLink,
            },
          },
        ],
      });
    } else {
      console.log("kakao 없음");
    }
  };
  const renderFloatingShareBtn = () => {
    // 로컬 스토리지에서 companyId 확인
    const companyId = sessionStorage.getItem("companyId");
    if (companyId == 1 || companyId == null) {
      return (
        <a
          className="fixed bottom-16 backdrop-blur"
          style={{
            right: "calc(50% - 190px)",
            background: "rgba(255, 255, 255, 0.7)", // 반투명 배경
            borderRadius: "50%", // 둥근 배경
            padding: "0px", // 버튼 주변 여백
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // 그림자 효과
            backdropFilter: "blur(10px)", // 블러 효과
          }}
          onClick={shareBtn}
        >
          <img
            src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/dfp_share.png"
            alt="Floating Button"
            className="w-11 h-11 rounded-full shadow-lg cursor-pointer"
          />
        </a>
      );
    }

    // 버튼 숨기기 (렌더링 안 함)
    return null;
  };

  // 평균 가격 계산 함수
  const calculateAveragePrice = (bsComparisons) => {
    if (bsComparisons?.length > 0) {
      const total = bsComparisons.reduce(
        (sum, item) => sum + item.price_member,
        0
      );
      const average = total / bsComparisons.length;
      setAveragePrice(Math.floor(average)); // 평균을 정수로 변환하여 설정
    }
  };

  const handlePurchase = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      return navigate("/loginbsdf", { state: { from: location } });
    }

    setIsPurchaseModalOpen(!isPurchaseModalOpen);
  };

  const ExplainModalhandler = () => {
    setIsExplainModalOpen(!isExplainModalOpen);
  };

  const CompareModalhandler = () => {
    setIsCompareModalOpen(!isCompareModalOpen);
  };

  useEffect(() => {
    if (activeTab) {
      const el = document.getElementById(`${activeTab}`);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab == null) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  // 이전 상품으로 이동
  const goToPrevProduct = () => {
    if (data?.prev_next_info?.prev_item_id) {
      navigate(`/whisky365/${data.prev_next_info.prev_item_id}`);
    }
  };

  // 다음 상품으로 이동
  const goToNextProduct = () => {
    if (data?.prev_next_info?.next_item_id) {
      navigate(`/whisky365/${data.prev_next_info.next_item_id}`);
    }
  };
  // 슬라이드 감지 로직 (마우스 드래그 & 터치 이벤트)
  const handleTouchStart = (e) => {
    setTouchStartX(e.touches ? e.touches[0].clientX : e.clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches ? e.touches[0].clientX : e.clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      // 왼쪽에서 오른쪽으로 스와이프(Next)
      goToNextProduct();
    } else if (touchEndX - touchStartX > 50) {
      // 오른쪽에서 왼쪽으로 스와이프(Prev)
      goToPrevProduct();
    }
  };
  return (
    <WhiskyLayout
      pageName={"상품정보"}
      menu={true}
      iframe={iframeUrl}
      setIframeUrl={setIframeUrl}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}

      {data !== null && iframeUrl == null ? (
        <div className="mb-4 relative flex items-center justify-center">
          <button
            className="absolute left-2 top-1/2 transform -translate-y-1/2 z-10"
            onClick={goToPrevProduct}
          >
            <img
              src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/365_left.png"
              alt="Previous"
              className="w-8 h-8"
            />
          </button>

          <figure
            className="pt-4 flex justify-center"
            onMouseDown={handleTouchStart}
            onMouseMove={handleTouchMove}
            onMouseUp={handleTouchEnd}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img
              src={data?.img_url}
              alt={`${data?.name}`}
              className="object-contain w-full max-w-[300px] h-auto bg-white"
            />
          </figure>

          <button
            className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10"
            onClick={goToNextProduct}
          >
            <img
              src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/365_left.png"
              alt="Next"
              className="rotate-180 w-8 h-8"
            />
          </button>
        </div>
      ) : null}

      {data !== null && iframeUrl == null && (
        <>
          <section aria-labelledby="product-details" className="pl-4 pr-4">
            {/* 상품명 */}
            <h2 className="font-semibold text-Gray70 my-3 text-left text-[13px] leading-[15px]">
              {data?.name_eng}
            </h2>
            <h2 className="font-semibold text-left mt-2 my-3 text-[18px] leading-[21px]">
              {data?.name}
            </h2>

            {/* 정상 가격 */}
            <div className="text-left mb-1">
              <span className="text-[14px] font-normal text-Gray50 leading-[16px]">
                <del>
                  ${data?.price_normal} (
                  {parseInt(data?.price_normal * exchangeRate).toLocaleString()}
                  원)
                </del>
              </span>
            </div>

            {/* 상품 가격 */}
            <div className="text-left mb-2 flex justify-between">
              <div className="flex w-[80%] items-center">
                <span className="text-[18px] font-bold text-red-400 mr-[4px]">
                  {parseInt(
                    ((data?.price_normal - data?.price_member) /
                      data?.price_normal) *
                      100
                  )}
                  %
                </span>
                <span className="text-[18px] font-bold leading-[21px]">
                  ${data?.price_member}
                </span>
                &nbsp;
                <span className="text-[14px] font-normal text-Gray70 leading-[16px]">
                  (
                  {parseInt(data?.price_member * exchangeRate).toLocaleString()}{" "}
                  원)
                </span>
                <span className="ml-3">
                  <img
                    src={LimitedLogo}
                    alt="limited"
                    className="w-auto h-[20px]"
                  />
                </span>
              </div>

              <div className="flex items-center">
                {" "}
                {/* flex와 items-center로 수직 가운데 정렬 */}
                <button onClick={ExplainModalhandler}>
                  <img src={Hook} alt="question mark" />
                </button>
              </div>
            </div>

            {/* 설명 모달 */}
            {isExplainModalOpen && (
              <ExplanationModal onClose={ExplainModalhandler} />
            )}

            {/* 별점, 리뷰 */}
            <div className="flex items-center my-4 text-Gray70 text-[14px]">
              <span>
                <img src={Star} alt="별점" />
              </span>
              &nbsp;
              {ratingData ? (
                <span>
                  {ratingData.average_rating % 1 === 0
                    ? `${ratingData.average_rating}.0`
                    : Number(ratingData.average_rating).toFixed(1)}
                </span>
              ) : null}
              <div className="mx-2 bg-Gray30 w-[2px] h-[12px]"></div>
              <span>
                {ratingData ? ratingData.total_reviews.length : 0}개의 리뷰
              </span>
            </div>

            {/* 잔여 수량 */}
            <div className="items-start bg-Sky p-2 rounded-lg my-5 px-3">
              <div className="text-[14px] font-semibold w-fit py-1">
                <span>총 {data?.reg_stock}병 수량 중</span> &nbsp;
                <span className="text-Aqua">{data?.tot_stock}병 남음</span>
              </div>
              <div className="text-[12px] font-normal text-Gray70 pb-1">
                현재 5명이 이 상품을 보고있어요! 👀
              </div>
            </div>

            {/* 가격 비교 */}
            <div>
              <button
                className="flex w-full justify-between bg-backgroundGray p-2 rounded-lg my-1 px-3"
                onClick={CompareModalhandler}
              >
                <div className="flex items-center text-[14px] text-Gray70">
                  <img src={DollarTag} className="w-5 mx-1" alt="Dollar" />
                  <div>
                    <span>인터넷 평균가</span>
                  </div>
                </div>
                <div className="font-bold text-[18px] flex items-center">
                  <div>
                    {parseInt(averagePrice * exchangeRate).toLocaleString()}원
                  </div>
                  <div className="mx-1">
                    <img
                      src={
                        "https://dfx-img.s3.ap-northeast-2.amazonaws.com/Vector+112.png"
                      }
                      alt="question mark"
                      width={13}
                    />
                  </div>
                </div>
              </button>
              <div className="flex justify-between items-center bg-backgroundGray p-2 rounded-lg mt-2 mb-2 px-3">
                <div className="flex items-center text-[14px] text-Gray70">
                  <img src={LowPrice} className="w-5 mx-1" alt="priceImg" />
                  <span>인터넷 평균가 대비</span>
                </div>

                {/* 가격 비교 모달*/}

                {isCompareModalOpen && (
                  <CompareModal
                    onClose={CompareModalhandler}
                    data={data?.bs_comparisons}
                    price_member={data?.price_member}
                  />
                )}
                <div className="flex text-Purple items-center">
                  <span className="mx-2 font-normal text-[16px]">
                    {/* {
                      data?.bs_comparisons?.length > 0
                        ? parseInt(
                            ((data.bs_comparisons.reduce(
                              (sum, item) => sum + item.price_member,
                              0
                            ) /
                              data.bs_comparisons.length -
                              data?.price_member) /
                              (data.bs_comparisons.reduce(
                                (sum, item) => sum + item.price_member,
                                0
                              ) /
                                data.bs_comparisons.length)) *
                              100
                          )
                        : 0 // bs_comparisons가 없을 경우 0% 출력
                    } */}
                    {averagePrice
                      ? "(" +
                        parseInt(
                          ((averagePrice * exchangeRate -
                            data?.price_member * exchangeRate) /
                            (averagePrice * exchangeRate)) *
                            100
                        ) +
                        "%)"
                      : 0}
                  </span>
                  <span className="font-bold text-[18px]">
                    -
                    {parseInt(
                      averagePrice * exchangeRate -
                        data?.price_member * exchangeRate
                    ).toLocaleString()}
                    원
                  </span>
                </div>
              </div>
            </div>
          </section>

          {/* 주의사항 */}
          <div className="flex items-start py-5 rounded-lg px-3 text-[13px] leading-[20px] font-normal text-Gray70">
            * 국내 유명 온라인 면세점 가격과 비교해보세요.
          </div>

          {/* 하단 네브 */}

          <div className="h-[10px] bg-DividerGray"></div>

          <div className="flex justify-around  border-b text-[14px] px-3">
            <button
              onClick={() => setActiveTab("상품상세")}
              id="상품상세"
              className={`p-4 ${
                activeTab === "상품상세"
                  ? "text-black font-bold border-b-[3px] border-black flex-1"
                  : "text-gray-500 flex-1"
              }`}
            >
              상품상세
            </button>
            <button
              onClick={() => setActiveTab("리뷰")}
              id="리뷰"
              className={`p-4 ${
                activeTab === "리뷰"
                  ? "text-black font-bold border-b-[3px] border-black flex-1"
                  : "text-gray-500 flex-1"
              }`}
            >
              상품리뷰
            </button>
          </div>

          {/* Tab */}
          {activeTab === "상품상세" && (
            <section
              aria-labelledby="product-description"
              className="w-full  bg-DividerGray pb-5"
            >
              {productDescription && (
                <div className="space-y-4 min-h-[100px]">
                  <img
                    src={productDescription}
                    alt="상품 설명"
                    className="w-full"
                  />
                </div>
              )}
            </section>
          )}

          {activeTab === "리뷰" && (
            <>
              <section aria-labelledby="reviews" className="w-full">
                <div className="min-h-[100px] px-3">
                  <FeedbackRating ratingData={ratingData} data={data} />
                </div>
              </section>
              <div className="h-[10px] bg-DividerGray"></div>
              <Review data={data} />
            </>
          )}

          {/* share button
          {renderFloatingShareBtn()}

          <div className="fixed bottom-0 left-0 right-0 w-full flex justify-center z-10">
            <button
              onClick={handlePurchase}
              className={
                "bg-black text-white font-semibold py-3 px-12 w-full max-w-[400px] text-center"
              }
              // disabled={Number(data?.tot_stock) <= 0 || data?.tot_stock == null}
            >
              최저가 구매하기
            </button>
          </div> */}
          <div className="fixed bottom-0 left-0 right-0 w-full flex flex-col items-center z-10 space-y-2">
            {/* Share button */}
            {renderFloatingShareBtn()}

            {/* Purchase button */}
            <button
              onClick={handlePurchase}
              className="bg-black text-white font-semibold py-3 px-12 w-full max-w-[400px] text-center"
              // disabled={Number(data?.tot_stock) <= 0 || data?.tot_stock == null}
            >
              최저가 구매하기
            </button>
          </div>

          {isPurchaseModalOpen && (
            <PurchaseModal
              setIframeUrl={setIframeUrl}
              onClose={handlePurchase}
              purchaseUrl={purchaseUrl}
            />
          )}
        </>
      )}

      {iframeUrl && (
        <iframe
          id="bsFrame"
          src={iframeUrl}
          className="w-full h-[850px] border border-gray-300"
          title="Purchase Site"
        />
      )}
    </WhiskyLayout>
  );
};

export default Whisky;
