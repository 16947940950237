// CenteredImage.js
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // 여기를 수정했습니다.
import { DutyFreeTabLayout } from "../../component/coupon/DutyFreeTabLayout";
import { CouponDisplay } from "../../component/coupon/CouponDisplay";
import { TabDisplayLayout } from "../../component/coupon/TabDisplayLayout";


const MAIN_BANNER_IMAGE_URL =
  "https://dfx-img.s3.ap-northeast-2.amazonaws.com/event_back.gif";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
`;

const LoginButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;

const CouponContainer = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;

  border: 1px solid #ccc;
  border-radius: 10px;
  // background-color: #f9f9f9;
`;

const ImageContainer = styled.div`
  width: ${(props) => props.width || "100%"};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: auto;
  }
`;

const EventCouponDisplay = styled.div`
  min-height: 100vh;
`;

const AiaCoupon = ({ src, alt }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUsername(decodedToken.name); // assuming 'name' is the key in the token
        setIsLoggedIn(true);
      } catch (error) {
        console.error("Failed to decode token", error);
        setIsLoggedIn(false);
      }
    }

  }, []);

  const handleLoginClick = () => {
    navigate("/login"); // 로그인 페이지로 이동
  };

  return (
    <Container>
      {/* {isLoggedIn ? ( */}
      <CouponContainer>
        {/* 이미지 */}
        <ImageContainer width="100%">
          <img src={MAIN_BANNER_IMAGE_URL} alt={alt} />
        </ImageContainer>
        {/* 쿠폰 컴포넌트 */}
        <EventCouponDisplay>
          <TabDisplayLayout></TabDisplayLayout>
        </EventCouponDisplay>
      </CouponContainer>
      {/* ) : (
        <>
          <div>로그인이 필요합니다.</div>
          <LoginButton onClick={handleLoginClick}>로그인</LoginButton>
        </>
      )} */}
    </Container>
  );
};

export default AiaCoupon;
