import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export const FloatingButton = ({ bottom, onClick, imgSrc, link }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (link) {
      window.location.href = link; // 외부 링크로 이동
    } else if (onClick) {
      onClick(); // 내부 클릭 핸들러 실행
    }
  };

  return (
    <FloatingButtonContainer bottom={bottom} onClick={handleClick}>
      <img
        src={imgSrc}
        alt="로고 이미지"
        style={{ width: "50px", height: "auto" }}
      />
    </FloatingButtonContainer>
  );
};

const FloatingButtonContainer = styled.div`
  position: fixed;
  bottom: ${(props) => props.bottom || "5px"};
  right: 50%; /* 중앙 기준으로 우측 끝에 위치 */
  transform: translateX(200px); /* 우측으로 이동 */
  color: #fff;
  padding: 10px 20px;
  border-radius: 80%;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.01); /* Hover 효과 */
  }
`;
